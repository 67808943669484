/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
// import Helmet from 'react-helmet'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import { showNotification } from '@mantine/notifications'

import { Input, Textarea, Button } from '@mantine/core'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'Simple Form Ajax',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Thanks for your enquiry, we will get back to you soon',
    errorMessage:
      'There is a problem, your message has not been sent, please try contacting us via email'
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(`${form.action}?${stringify(data)}`, {
      method: 'POST'
    })
      .then((res) => {
        if (res.ok) {
          return res
        }
        throw new Error('Network error')
      })
      .then(() => {
        form.reset()
        showNotification({
          position: 'top-center',
          autoClose: 8000,
          title: 'Success',
          message: 'Your message was sent'
        })
        this.setState({
          alert: this.props.successMessage,
          disabled: false
        })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        showNotification({
          position: 'top-center',
          autoClose: 8000,
          title: 'Error',
          message: 'Your Enquiry was not sent - please try again.'
        })
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action } = this.props

    return (
      <form
        className="Form"
        name={name}
        action={action}
        onSubmit={this.handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        {this.state.alert && (
          <div className="Form--Alert">{this.state.alert}</div>
        )}
        <div className="Form--Group">
          <p className="ohnohoney">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <Input.Wrapper style={{ width: '48%' }} label="First Name" required>
            <Input required name="firstname" placeholder="First Name" />
          </Input.Wrapper>
          <Input.Wrapper style={{ width: '48%' }} label="Last Name" required>
            <Input required name="lastname" placeholder="Last Name" />
          </Input.Wrapper>
        </div>
        <label className="Form--Label">
          <Input.Wrapper
            style={{ width: '100%' }}
            label="Email Address"
            required
          >
            <Input name="email" placeholder="Email Address" />
          </Input.Wrapper>
        </label>
        <label className="Form--Label">
          <label className="Form--Label">
            <Input.Wrapper style={{ width: '100%' }} label="Telephone" required>
              <Input required name="phone" placeholder="Telephone" />
            </Input.Wrapper>
          </label>
        </label>
        <label className="Form--Label">
          <Textarea
            fullWidth
            required
            name="message"
            style={{ width: '100%' }}
            placeholder="Message"
            label="Message"
            autosize
            minRows={10}
          />
        </label>
        {!!subject && <input type="hidden" name="subject" value={subject} />}
        <input type="hidden" name="form-name" value={name} />
        <Button
          disabled={this.state.disabled}
          value="Submit Booking Enquiry"
          type="submit"
        >
          SUBMIT
        </Button>
      </form>
    )
  }
}

export default Form
